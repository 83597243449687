@font-face {
  font-family: Museo_Slab_500;
  src: url(Museo_Slab_500.otf);
}
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {font-family: 'Poppins', sans-serif;}
/* .container-fluid {  width: 90%;} */
.grayBg { background-color: #f1f1f1;}
.topBar { background-color: #066091; color: #fff;}
.topBar a.nav-link , .topBar a{ color: #fff;     font-size: 13px;    letter-spacing: 1px;}
.justify-content-center2 { justify-content: right;}
p.card-text {height: 90px;}
.topBar a.nav-link:hover { color:#91D33C;}
.navbar { background: #0c78b3;border: none; margin-bottom: 0;  min-height: 60px;  color: #fff;}
.aboutCircle img { margin: auto; max-width: 80%;}
.active{ color:#91D33C!important; }
.APP .active { color: #066091!important;  background: #f7f7f7;
}.card-title { color: #0082c9;     text-transform: uppercase;}
 .lifeAtInovera h4 { padding-top: 10px ; color: #0082c9; font-size: 19px;  text-transform: capitalize;}
.pt-4.mb-5.lifeAtInovera.container .col-lg-9 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pt-4.mb-5.lifeAtInovera.container p { margin-bottom: 0px;}
.card {  margin-bottom: 20px;}
a.nav-link { font-weight: 500;  color: #91D33C;}
.d-grid h5{color:#0082c9; }
.beyondIcons img { margin-bottom: 20px;}
.beyondIcons h4 {font-size: 16px;  }
.APP .navbar { background-color: #ffffff!important; border: none;     padding: 0px;
}
.APP .APP li.nav-item:last-child {
  border: none;
}
.navbar-light { min-height: 50px;     padding: 0px;}
.navbar-brand img {
  max-width: 40%;
  padding: 5px 0px;
  height: auto;
}
.footerSection img {
  max-width: 60%;
  padding: 5px 0px;
  height: auto;
}
.ParentsDiv h4 {
  padding-top: 14px;
  color: #0082c9;
  font-size: 22px;
  text-align: left;
  text-transform: capitalize;
}
.greenBg{    color: #0082c9; padding: 0px 6px; font-weight: 700;}
.blueBg {    color: #38c0a9; padding: 0px 6px; font-weight: 700;}
.whiteBg { background-color: #fff;  color: #91D33C; padding: 0px 6px; font-weight: 500;}
.greenBor , .blueBor , .whiteBor{ position: relative; font-weight: 400; }
.greenBor, .blueBor {color: #80ae00; font-weight: 700;}
.whiteBor { color: #fff;}
/* .greenBor:after  , .blueBor:after  , .whiteBor:after {  content: ""; position: absolute; left: 0; bottom: 0; width: 100%; height: 2px;  } */
.table>thead { vertical-align: bottom; background: #91d33c; }
.faculty ul li { line-height: 25px; font-size: 15px; list-style-type: square;  margin-bottom: 10px;}
.prepareSection {  background-color: #91D33C;position: relative;}
.prepareSection img { max-width: 50%; margin: auto;}
/* .prepareSection::before { background-color: #91D33C; position: absolute; content: ""; left: 0; top: 0; height: 100%; width: 100%;} */
.greenBor:after {background-color:  #0082c9;}
.blueBor:after {  background-color:  #0082c9;}
.whiteBor:after { background-color:  #fff;}
.aboutUs p{ font-size: 17px; color: #000;}
.newsletterSection,.growthSection { background-size: cover;}
.greenCircle , .blueCircle {height: 220px; width: 220px; margin: auto;background-size: cover; background-position: center; background-repeat: no-repeat; border-radius: 50%; border:10px solid; }
.greenCircle { border-color: #91D33C;}
.blueCircle { border-color: #0082c9;}
.aboutCircle h4 { margin: 20px 0px; font-size: 16px; font-weight: 500;text-transform: uppercase;}
.prepareDiv {height: 130px; width: 130px;  margin: auto; border-radius: 50%; border:1px solid #fff; display: flex; justify-content: center; align-items: center;}
.prepareDiv .fa { font-size: 60px;line-height: 60px; color: #fff; }
.prepareSection h5 { color: #0c78b3; margin-top: 10px;font-size: 18px; text-transform: uppercase;  font-weight: 500;}
.navbar-nav a {  display: block;  color: #ffffff;  font-weight: 500;  padding: 10px 10px;   position: relative;     letter-spacing: 1px;  font-size: 17px;    text-transform: capitalize;} 
.cardSection { background-color: #f1f1f1;}
.container h1 { color: #000000; text-align: center; text-transform: uppercase; font-size: 33px;}
.APP .navbar li a { text-transform: uppercase  ;     letter-spacing: 1px;    font-size: 15px;
color: #066091;    line-height: 30px;

}
.APP li.nav-item { border-right: 1px solid #e1dfdf;}
.APP li.nav-item:hover {
  background: #f7f7f7;
}
.APP li.nav-item {
  border-right: 1px solid #e1dfdf;
}
/* ---- */
::-webkit-scrollbar {
  width: 5px
}

::-webkit-scrollbar-track {
  background: #f1f1f1
}

::-webkit-scrollbar-thumb {
  background: #888
}

::-webkit-scrollbar-thumb:hover {
  background: #555
}
/* ---- */
.card-body { overflow: auto;}
.footerSection { position: relative;  background-color: #0082c9;}
.footerSection h5{ color: #f1f1f1; font-size:25px;    font-weight: 700;}
.Cardtitle { text-transform: uppercase; color: #0082c9;   text-align: center;}
.applySection .BranchBox , .applySection .BranchBox3 { font-size: 20px; margin-bottom: 0px!important;}
.growthDiv  { position: relative;}
.growthImg1 , .growthImg2{ position: relative; background-size:cover;background-repeat: no-repeat; background-position:  center; min-height: 300px; display: flex;  align-items: center;    justify-content: center; }
.growthCaption { color: #fff; position: relative; text-transform: uppercase; -webkit-transition: .5s;  -o-transition: .5s;  transition: .5s; }
.growthDiv:hover .growthCaption {
  color: #91d33c; -webkit-transition: .5s;  -o-transition: .5s;  transition: .5s;
}
.growthDiv:hover .growthCaption .btn {color: #91d33c; -webkit-transition: .5s;  -o-transition: .5s;  transition: .5s; border: 2px solid #91d33c; }
/* .growthImg1::before { position:absolute; content: ''; height: 100%; width: 100%; left: 0; top: 0; background-color:rgb(0 123 179 / 70%)}
.growthImg2::before { position:absolute; content: ''; height: 100%; width: 100%; left: 0; top: 0; background-color: rgb(0 112 51 / 70%);} */
.growthCaption .btn  {  background-color: transparent; border:2px solid #fff; border-radius: 14px; -webkit-transition: .5s; -o-transition: .5s; transition: .5s; }
.growthCaption .btn:hover  {  background-color: transparent; border:2px solid #fff; border-radius: 0px; -webkit-transition: .5s;  -o-transition: .5s;  transition: .5s;}
.growSection .BranchBox , .growSection .BranchBox3 { min-height: 300px; text-align: center;} 
.growSection .BranchBox h4 , .growSection .BranchBox3 h4 {  text-align: center;} 
.growSection .blueBg {   padding: 10px 6px;
  font-size: 24px;
  color: #0078bf !important;
  text-transform: initial;
  background-color: #ffffff;
  border: 1px solid;
  box-shadow: 1px 1px 1px 1px;
}
.lifeAtInovera .card { margin-bottom: 30px;;}
.growthCaption  h4.text-center.mainText {
  min-height: 200px;
  display: flex;
  align-items: center;
}
.growthSection .row .col-lg-6:first-child::after { position: absolute; right: 0; border:2px solid #fff; height: 200px;  }
.valueIcons img { max-width: 100px;}
.valueIcons h4 {
   font-size: 22px;
   margin: 12px auto;    font-weight: 700;

}.valueIcons p {   font-size: 16px;  }
.form-label {
margin-bottom: 0.5rem;
margin-top: 0.5rem;    text-transform: inherit;
}
h5 li , .col-lg-12 h5{ line-height: 31px;}
/* .container p a {
color:  #0082c9 !important;
font-weight: 500;
padding: 3px;
} */
.bookingform { background-color: #f1f1f1; padding: 0px 15px;   border-radius:10px;}
.AboutBg { position: relative ;}
.AboutBg h1{ position: relative ; color: #fff;}
.footerSection .fa { margin-right: 15px; background-color: #fff; color:#0082c9 ; width: 30px; height: 30px;line-height: 30px; text-align: center; border-radius: 50%; -webkit-transition: .5s;  -o-transition: .5s;  transition: .5s;}
.footerSection .fa:hover{background-color:#0082c9 ; color:#fff ;-webkit-transition: .5s;  -o-transition: .5s;  transition: .5s;}
.AboutBg:before { position: absolute ; content:""; left: 0; right: 0; width: 100%; height: 100%;background-color: rgb(0 0 0 / 70%);  }
.footerSection .container p a {
color:  #e7e7e7 !important;
font-weight: 500;
padding: 3px;
}

.keyPrograms {
  min-height: 395px;
  background-size: cover;
  background-position: -46px -86px;
  background-repeat: no-repeat;
}

.footerSection .container p , .footerSection li a , .footerSection li a.active{ color: #fff;} 
button.btn.btn-success ,button.btn.btn-success:hover {
  background-color: #91d33c;
  border: #91d33c;
}
.form-label {
   
  font-size: 14px;
}
body p , table td{
font-size: 16px; line-height: 30px;
}
a:hover , a{
text-decoration-line: none;
}
.contactUS .fa {
color: #0082c9;
font-size: 17px;
margin-right: 7px;
}
h3.py-3, h4.pt-3 {
color:  #0082c9;
}
.productDiv {
  min-height: 300px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  border-radius: 0;
  position: relative;
  margin-bottom: 25px;
  overflow: hidden;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 10px;
}
.productDiv .captionDiv {
  color: #0098a3;
  padding: 20px 10px;
  bottom: 0;
  position: absolute;
  width: 100%;
  top: 0;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 21px;
  text-transform: capitalize;
  font-weight: 400;
  opacity: 1;
  padding: 15px 30px;
  transition: .5s;
  display: flex
;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.team .productDiv .captionDiv { opacity: 0;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px); -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;}


.owl-carousel .productDiv .captionDiv {
width: 100%;
top: 201px;}

.justify-content-center .productDiv .captionDiv {
width: 90%;
top: 201px;}
.productDiv .captionDiv .plus {     position: absolute;
right: 8px;
top: 0;
text-align: center;
-webkit-transition: .5s;
-o-transition: .5s;
transition: .5s;
-webkit-transition-delay: .2s;
-o-transition-delay: .2s;
transition-delay: .2s;
-webkit-transform: translateX(40px);
-ms-transform: translateX(40px);
transform: translateX(40px); }


/*  .productDiv .captionDiv {
position: absolute;
left: 0;
bottom: 0;
opacity: 0;
padding: 15px 30px;
-webkit-transition: .5s;
-o-transition: .5s;
transition: .5s;
overflow: hidden;
width: 90%;
}
*/

.productDiv .captionDiv h3 {
  font-size: 18px;
  font-weight: 700;
-webkit-transition: .4s;
-o-transition: .4s;
transition: .4s;
color: #fff;    margin-bottom: 0px!important;  text-align: center;

-webkit-transform: translateY(0px);
-ms-transform: translateY(0px);
transform: translateY(0px);
opacity: 1;
-webkit-transition-delay: .4s;
-o-transition-delay: .4s;
transition-delay: .4s;
}
.productDiv:hover .captionDiv h3 {
opacity: 1;
-webkit-transform: translateY(0px);
-ms-transform: translateY(0px);
transform: translateY(0px);
}
.productDiv:hover .captionDiv:before {
opacity: 1;
-webkit-transform: translateX(0);
-ms-transform: translateX(0);
transform: translateX(0);  -webkit-transition: .5s;
-o-transition: .5s;
transition: .5s;}

.team .productDiv:hover .captionDiv:before {
  opacity: 1;
  height: 100%;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px); -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s; }
.productDiv .captionDiv p {
  font-size: 15px;
  color: #fff;
  position: relative;
  margin-bottom: 5px;
}

.impressions .productDiv .captionDiv:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(241 119 67 / 90%);
  opacity: 1;
  transition: .5s;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.impressions .productDiv .captionDiv h3{ font-size: 14px;}
.productDiv:after{ position: absolute; right: 0; top:0; height: 100%; width:7px; background-color: #25d366; content: "";}

.impressions .productDiv:after {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 7px;
  background-color: #f17743;
  content: "";
}
.productDiv .captionDiv p a { color: #fff!important; font-size: 15px;}
.productDiv .captionDiv:before {
position: absolute;
content: '';
left: 0;
top: 0;
width: 100%;
height: 10%;
background-color:rgb(37 211 102 / 70%);
opacity: 1;
-webkit-transition: .5s;
-o-transition: .5s;
transition: .5s;
-webkit-transform: translateX(0px);
-ms-transform: translateX(0px);
transform: translateX(0px);
}
.productDiv .captionDiv .plus{    position: absolute;
right: 8px;
top: 0;
text-align: center;
-webkit-transition: .5s;
-o-transition: .5s;
transition: .5s;
opacity: 0;
-webkit-transition-delay: .2s;
-o-transition-delay: .2s;
transition-delay: .2s;
-webkit-transform: translateX(40px);
-ms-transform: translateX(40px);
transform: translateX(40px);
}
.impressions .productDiv .captionDiv { padding: 8px 10px;
  top: 241px; display: flex;
  align-items: center;
  text-align: center;}
.productDiv:hover .captionDiv {
opacity: 1;
}
.lifeAtInovera iframe { margin-bottom: 20px;}
.productDiv:hover .plus {    
-webkit-transform: translateX(0px);
-ms-transform: translateX(0px);
transform: translateX(0px); opacity: 1; }

.productDiv .captionDiv .plus a {
background-color:transparent;
color: #fff;
width: 40px;
height: 60px;
line-height: 60px;
display: inline-block;
font-size: 30px;
font-weight: 700;
text-decoration: none;
}
/* ===================== */
.owl-theme .owl-nav .owl-left , .owl-theme .owl-nav .owl-next{ left:0; position: relative; transition: all 0.3s ease-in-out;}
.owl-theme .owl-nav [class*='owl-']:hover {
background: transparent;
color: #FFF;
text-decoration: none;
}
.owl-theme .owl-nav .owl-left:hover{  right: 10px; position: relative; transition: all 0.3s ease-in-out;}
.owl-theme .owl-nav .owl-next:hover {  left: 10px;  position: relative;transition: all 0.3s ease-in-out;
}



/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

.megamenu {
position: static;
}

.megamenu .dropdown-menu {
background: none;
border: none;
width: 100%;
}

/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/

.APP  {
background: #eaafc8;
background: -webkit-linear-gradient(to right, #eaafc8, #654ea3);
background: linear-gradient(to right, #eaafc8, #654ea3);
/* min-height: 100vh; */
}

code {
color: #745eb1;
background: #fff;
padding: 0.1rem 0.2rem;
border-radius: 0.2rem;
}

.text-uppercase {
letter-spacing: 0.08em;
}
.megaimaga
{
background: center center url("https://therichpost.com/wp-content/uploads/2021/11/mega.png")no-repeat; background-size: cover;
}

@media all and (max-width:767px){
  .lifeAtInovera  h5 { text-align: center;}
  .art-side-form { width: 100%!important;}
  body p , body li { font-size: 16px!important;}
  .APP .navbar li a { text-transform: uppercase;}
  .APP { margin-bottom: 20px;}
    .container h1 {     font-size: 23px;    }
    .BranchBox2 { min-height: auto!important;}
    .applySection .BranchBox, .applySection .BranchBox3 { margin-bottom: 20px!important;}
    .col-lg-12 .blueBg { border-radius: 4px; padding: 5px;}
    .navbar-brand img, .footerSection img {     max-width: 41%;    height: auto; }
    .topBar a.nav-link, .topBar a {  color: #fff;   font-size: 11px;   letter-spacing: 1px;  padding: 4px 4px;
  }
  .pt-4.mb-5.lifeAtInovera.container img {
    display: block;
    margin: auto;
}
  .justify-content-center2 {
    justify-content: center;
}.navbar-nav a  { font-size: 14px; text-transform: capitalize;}
span.navbar-brand { /* margin-right: 0; */  max-width: 68%;}  .grayBg img { margin-bottom: 20px;}

}



/* --------------------------------------------------------------------------- */
/* *{
    margin:0px;
    padding:0px;
  } */
  
  .circular-sb  {
    width: 250px;
    height: 250px;
    padding: 10px 10px;
    margin: 30px auto;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    position: relative;
    display: flex;
    color: #fff;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; 
    background-color:#0082c9;
   border: 5px solid #0082c9;

}
  
.circular-sb .circDesc { font-size: 15px;font-weight: 500;
    padding: 11px 20px;  position: absolute; color: #0082c9;
    opacity: 0; opacity: 0;position: absolute;  opacity: 0;transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; 
    -webkit-transform: translateY(70px);
    -ms-transform: translateY(70px);
    transform: translateY(70px); display: flex; align-items: center; }
    .circular-sb:hover {       background-color:#fff;
        border: 5px solid #0082c9;
        color: #ffffff;  -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
  /*right circle shape speech bubble*/
  .circular-sb:hover .circDesc{ opacity: 1; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; color: #0082c9;transform: translateY(0px);     transition-delay: .1s;

    opacity: 1; }


  .circle1 {
    border: 5px solid #91d33c;
    position: absolute;
    width: 25px;
    padding: 20px;
    border-radius: 50%;
    right: -15px;
    bottom: 23px;
  }
  
  .circle1:before {
    content: "";
    position: absolute;
    width: 25px;
    padding: 20px;
    border-radius: 50%;
    right: 0px;
    bottom: 0px;
    background: #fff;
  }
  
  .circle2 {
    border: 5px solid #0082c9;
    position: absolute;
    width: 5px;
    padding: 10px 15px;
    border-radius: 50%;
    right: -60px;
    bottom: 5px;
  }
  .footerSection .partners img {
    max-width: 60px;
    padding: 5px 0px;
    height: auto;
    margin-right: 5px;
}
  .footerSection .partners h4 { color:#fff;}
  /*left circle shape speech bubble*/
  .nav-item.dropdown .nav-link:focus, .nav-item.dropdown .nav-link:hover ,  .navbar-nav .show>.nav-link{
    color: rgb(255 255 255);
}
a.dropdown-item {
  color: #000!important;
}
.navbar-nav .dropdown-item a { color: #000;}
  .circle3 {
    border: 5px solid #0082c9;
    position: absolute;
    width: 25px;
    padding: 20px;
    border-radius: 50%;
    left: -10px;
    bottom: 10px;
  }
  
  .circle3:before {
    content: "";
    position: absolute;
    width: 25px;
    padding: 20px;
    border-radius: 50%;
    right: 0px;
    bottom: 0px;
    background: #fff;
  }
  
  .circle4 {
    border: 5px solid #0082c9;
    position: absolute;
    width: 5px;
    padding: 10px 15px;
    border-radius: 50%;
    left: -60px;
    bottom: 5px;
  }
  
  
  
  
  
  
  
  /* youtube link */
  .youtube{
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 160px;
    text-align: center;
    padding: 15px 10px;
    background: #bb0000;
    border-radius: 0px;
  }
  
  .youtube a{
    text-decoration: none;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 1px;
  }

  /* ---------------------------------------------------------- */
  .propertyRate { background-color: #91d33c; padding: 20px 0px; }
.propertyRate h4 { color: #fff; text-align: center; font-size: 23px;  }
    .blink{
        width:100%;
         padding: 15px;  
        text-align: center;
        line-height: 50px;
        text-shadow: 2px 2px 8px black;
    }
   .blink span{
        font-size: 30px;
         color: #fff;
        animation: blink 1s linear infinite;
    }
@keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}


 
.iconBox {
    border: 2px solid #0082c9;
    padding: 12px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    background-color:#0082c9;min-height: 80px;
}
.BranchBox { 
  border:none;
  padding: 12px 15px;
  border-radius: 10px;
   margin-bottom: 30px;
   color: #fff;
  background-color:#0082c9;
  margin-bottom: 20px;
}
.BranchBox3 { 
  border:none;
  padding: 12px 15px;
  border-radius: 10px;
   margin-bottom: 30px;
   color: #000;
  background-color:#91d33c;
  margin-bottom: 20px;
  text-transform: uppercase;font-weight: 500;
}
.BranchBox2 { border:none;
  padding: 12px 15px;
  border-radius: 10px;
   margin-bottom: 30px;
   color: #000;
  background-color:#91d33c; min-height: 280px;
  margin-bottom: 20px;} 
  .growthDiv:nth-child(odd) {
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
}
  .visitBox .BranchBox ,.visitBox .BranchBox3 { min-height: 100px; display: flex; align-items: center; justify-content: center;}
.BranchBox img , .BranchBox2 img { margin-bottom:15px; }
.BranchBox2 h4 { font-size: 1.2rem; }
.privacyPolicy h4 { margin-top: 25px;}

#connectivity img {
    float: left;
    margin-right: 10px;
    max-width: 20%;
}
 #connectivity p {
    font-size: 13px;
    color: #ffffff;
    background: transparent;
    border-radius: 10px;
    margin-bottom: 0px;
    padding: 2px 8px;
    /* line-height: 27px; */
}
/* =====================================Subheadr ===================================*/
section.breadcrumb-area :hover .breadcrumb-area { transform: scale(1.1);  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;  }
.breadcrumb-area  {background-size: cover;position: relative;min-height: 200px;background-repeat: no-repeat; margin-top: 0px;  display: flex;
  align-items: center; transform: scale(1);     background-position: center;
}
.breadcrumb-area:before {
 position: absolute; content: ''; left: 0; top: 0; width: 100%;height: 100%; background: linear-gradient(1deg, #0c78b3cc, transparent); }

.breadcrumb {
display: inline-block;
-ms-flex-wrap: wrap;
flex-wrap: unset;
padding: 0;
margin-bottom: 0;
list-style: none;
background-color: unset;
border-radius: 0;

}
.breadcrumb li {display: inline-block;}
.breadcrumb li a {
font-size: 16px;
color: #fff;
font-weight: 500;
}
.breadcrumb-item + .breadcrumb-item::before {
display: inline-block;
padding-right: 15px;
padding-left: 10px;
color: #ddd;
content: "|";
}
.breadcrumb-title h2 {
font-size: 48px;
margin-bottom: 25px;
line-height: 1;
color: #fff; text-shadow: 1px 2px black;    text-transform: capitalize; font-weight: 700;letter-spacing: 3px;

}
.breadcrumb-title p {
margin-bottom: 0;
color: #777;
font-size: 16px;
}
.breadcrumb > .active {
  color: #ffffff !important;
  font-weight: 500;
}
.objectives li {
 position: relative;
  color: #000;
  font-size: 15px;
  padding: 14px 0px;
  padding-left: 15px;
}

.breadcrumb-wrap:first-child {
  display: block;
    padding: 20px;
    color: #fff;
    position: relative;
    text-align: center;
}
.objectives li:before { 
 position: absolute;
  content: "";
  width: 0;
  left: -12px;
  height: 0;
  top: 18px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #4fae57; }

  /*--------------------------------*/

  /*-------------------sidebar----------*/

 /* Sidebar */
.services-details-area .sidebar-item {
  margin-bottom: 3rem;
}
.services-details-area  ul {
  list-style: none;
  margin-left: -40px;
}
.services-details-area .sidebar-item:last-child {
  margin-bottom: 0;
}

.services-details-area .sidebar-item {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  padding: 20px;
  border-radius: 0px;
}

.services-details-area .sidebar-item .title h4 {
  font-weight: 600;
  position: relative;
  padding-left: 15px;
  margin-bottom: 25px;
}

.services-details-area .sidebar-item .title h4::after {
  position: absolute;
  left: 0;
  top: 3px;
  content: "";
  height: 20px;
  width: 3px;
  background: #086AD8;
}

.services-details-area .sidebar-item.link li {
  display: block;
}
.BranchBox p a{ color: #fff!important;}
.BranchBox2 p a{ color: #000!important;}
.services-details-area .sidebar-item.link li a {
  display: block;
  padding: 15px 15px;
  border-bottom: 1px solid #e7e7e7;
  position: relative;
  overflow: hidden;
  color: #066091;
  text-transform: capitalize;
  cursor: pointer;
}

.services-details-area .sidebar-item.link {
  border: 1px solid #e7e7e7;
  border-radius: 0px;
  background: #fff;
}

.services-details-area .sidebar-item.link li:last-child a {
  border: none;
}

.services-details-area .sidebar-item.link li a:hover, 
.services-details-area .sidebar-item.link li a.active {
  color: #ffffff;
}
.lifeAtInovera .col-sm-6 li { font-size: 14px; }
.services-details-area .sidebar-item.link li a.active::after {
  position: absolute;
  right: 10px;
  top: 50%;
  content: "\f105";
  border-radius: 50%;
  font-family: "Font Awesome 5 Free";
  font-weight: 660;
  transform: translateY(-50%);
  font-size: 20px;
}

.services-details-area .sidebar-item.link li a::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 0;
  background:linear-gradient(90deg, rgb(0 130 201) 0%, rgb(6 96 145) 100%);
  transition: all 0.35s ease-in-out;
  z-index: -1;
}
a.bt-sidebar.active {
  color: #fff!important;
}.services-details-area .sidebar-item.link li a:hover::before,
.services-details-area .sidebar-item.link li a.active::before {
width: 100%;
}

.services-details-area .sidebar-item.banner {
  border-radius: 0px;
  padding: 0;
  border: none;
  background: transparent;
}
.services-details-area hr {
  margin: 1rem 0rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: .25;
}
.services-details-area .sidebar-item.banner .thumb {
  position: relative;
  z-index: 1;
}

.services-details-area .sidebar-item.banner .thumb img {
  border-radius: 0px;
}

.services-details-area .sidebar-item.banner .thumb .content {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 30px;
}

.services-details-area .sidebar-item.banner .thumb::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #000000;
  border-radius: 0px;
  opacity: 0.5;
}

.services-details-area .sidebar-item.banner .thumb .content h3 {
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 0;
  font-size: 26px;
}

.services-details-area .sidebar-item.banner .thumb .content h3 i {
  height: 45px;
  width: 45px;
  line-height: 45px;
  background: #086AD8;
  border-radius: 50%;
  font-size: 20px;
  position: relative;
  margin-right: 10px;
  z-index: 1;
}

.services-details-area .sidebar-item.banner .thumb .content h3 i::after {
  position: absolute;
  left: -10%;
  top: -10%;
  content: "";
  height: 120%;
  width: 120%;
  background: #086AD8;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.5;
}

.services-details-area .sidebar-item.banner .thumb .content h5 {
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 25px;
}

.services-details-area .sidebar-item.brochure a {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.services-details-area .sidebar-item.brochure a i {
  font-size: 40px;
  font-weight: 500;
  margin-right: 10px;
  color: #086AD8;
}

.services-details-area .sidebar-item.brochure a:last-child {
  margin-top: 15px;
}

/*-------------------------------*/

@media all and (min-width:768px){
 .academics .productDiv  { margin-bottom: 0px;    background-position: center;
 }
}


/* --------------------------------------------- */
.art-side-form {
  position: fixed;
  right: 0;
  background: #fff;
  padding:22px;
  z-index: 10000;
  width: 500px;
   -webkit-box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(100%, -50%, 0);
  transform: translate3d(100%, -50%, 0);
  -webkit-transition: -webkit-transform 0.8s;
  transition: -webkit-transform 0.8s;
  -o-transition: transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  -o-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  top: 50%;
}

.art-side-form .close {
  top: 0.875rem;
  right: 1.25rem;
}

.close .cross {
  width: 1.125rem;
  height: 1.125rem;
  display: inline-block;
  right: 1.875rem;
  padding-left: 0.75rem;
  cursor: pointer;
}
.art-side-form .close .cross:after, .art-side-form .close .cross:before {
  background: #05122C;
}
.art-side-form .close .cross:after {
  bottom: -2px;
}
.art-side-form .close .cross:after, .art-side-form .close .cross:before {
  background: #05122C;
}

.art-side-form .art-side-form-tab {
  position: absolute;
  left: -50px;
  width: 50px;
  height: 175px;
  bottom: 20%;
  background:#91d33c;
  /*top: calc(50% - 100px);*/
  border-radius: 0.625rem 0 0 0.625rem;
  color: #fff;
  -webkit-box-shadow: -2px 0px 5px 1px rgba(0, 0, 0, 0.25);
  box-shadow: -2px 0px 5px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.art-side-form .art-side-form-side-text {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  margin: 0;
  width: 0;
  white-space: nowrap;
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  text-align: left;
  position: absolute;
  padding-bottom: 1rem;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.1em;
}
.art-side-form .art-side-form-side-icon {
  position: absolute;
  bottom: 8px;
  width: 100%;
  left: 0;
  font-size: 1.25rem;
  text-align: center;

}
.art-side-form-side-icon .fa {
  font-size: 17px;
}
.rs-icon-wrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.art-side-form .art-side-form-side-icon {
  position: absolute;
  bottom: 8px;
  width: 100%;
  left: 0;
  font-size: 1.25rem;
  text-align: center;
}
.art-side-form h3 {
  color: #05122C;
  margin-bottom: 9px;
  font-size: 28px;
  font-weight: 700;
  margin-top: 0px;    font-family: "IBM Plex Sans", sans-serif;
}
.art-side-form p {
  margin-bottom: 13px;
  color: #4F5772;
  font-size: 15px;
}

.contact-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.art-side-form.open {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.contact-form .input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.contact-form input[type=email],  .contact-form input[type=text] {
  height: 40px;
  border-radius: 0.125rem;
  border: 0;
  color: #4F5772;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.art-side-form .contact-form input[type=text], .art-side-form .contact-form textarea {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #CED1DC;
  font-size: 0.9375rem;
  margin-bottom: 15px;
}

.buttonFee .btn-primary{  
  background-color: #ff7f46;
  border-color: #ff7f46;}

.contact-form .half {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 49%;
  -ms-flex-preferred-size: 49%;
  flex-basis: 49%;
}

.contact-form .full {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.art-side-form .contact-form .checkbox-wrapper {
  margin: 0rem 0 1rem;
}
.art-side-form .contact-form input[type=email], .art-side-form .contact-form input[type=text], .art-side-form .contact-form textarea {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #CED1DC;
  font-size: 14px;
  margin-bottom: 10px;
}
.contact-form .full {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.button.green, button.green {
  background: #91d33c;
  color: #fff;
}
.contact-form button {
  height: 45px;
  text-transform: uppercase;
  border: 0;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 700;
}

.contact-form .privacy-policy {
  margin-top: 0.875rem;
  font-size: 0.875rem;
  color: #999FB7;
}

.art-side-form .contact-form .checkbox-wrapper {
  margin: 0rem 0 1rem;
}
.contact-form .checkbox-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.5rem 0 1.5rem;
}

.contact-form .checkbox-wrapper .contact-check-intro {
  margin-right: 1rem;
  font-weight: 600;margin-top: -4px;
}

.contact-form .checkbox-wrapper label input {
  margin-right: 0.5rem;
}
.contact-form .checkbox-wrapper label {
  margin-right: 1.3125rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
}

#art-side-form .close {
  float: right;
  font-size: 31px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .5;
  margin-top: -6px;cursor: pointer;
}



.currentOpenings .growthCaption h4.text-center.mainText{ min-height: auto!important; display: block;     color: #91d33c;
}

.currentOpenings .growthCaption{text-transform: inherit;}

@media (min-width: 1200px){
 .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px;
}

}

.float .fa , .float_left .fa { background-color: transparent; color:#fff;}
.float {
  background-color: #25d366;
  border-radius: 50px;
  bottom: 25px;
  box-shadow: 2px 2px 3px #999;
  color: #fff;
  font-size: 30px;
  height: 60px;
  line-height: 60px;
  position: fixed;
  right: 8px;
  text-align: center;
  width: 60px;
  z-index: 100;
}
.orangeT {
  color: #ff7f46;
}
.greenT {
  color: #91d33c;
}
.blueT{
  color: #4682ff;
}
.valueIcons .valueBox {
  background-color: #fff;
  height:300px;
  border: 1px solid #d5d5d5;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  -webkit-transition: .5s;
-o-transition: .5s;
transition: .5s;
}
.productDiv{-webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;}
.valueIcons .valueBox:hover ,.productDiv:hover{
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  border-radius: 0px;
  margin-top: -10px;
}

.valueIcons img {
  max-width: 80px;
  margin-top: 10px;
}

.whyChImage { min-height:250px; background-size:cover; background-position:center; background-repeat: no-repeat;position: relative;  }
.HowDivImage{ min-height:250px; background-size:cover; background-position:center; background-repeat: no-repeat;position: relative; }
.whyChImage .caption {
    position: absolute;
    content: "";
    bottom: 0;
    height: auto;
    background-color: #4681ff;
    color: #fff;
    font-size: 20px;
    text-align: left;
    left: 0;
    right: 0;
    width: 100%;
    padding: 12px 10px;
    text-align: center;
}
.howDivCaption{ position: absolute;
    content: "";
     height: auto;
    background-color: #4681ff;
    color: #fff;
    font-size: 18px;
    text-align: left;
    left: 10;
    top: 10;
    width: 80%;
    padding: 12px 10px;
    text-align: center;}

    .bg-white{background-color: #fff;}
    .bg-gray{background-color: #e5e5e5;}
    .lifeAtInovera  .col-sm-7 { display: flex;
          align-items: start;
          justify-content: center;
          align-content: center;
          flex-direction: column; min-height: 250px;}; 

