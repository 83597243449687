.newFooter {
    position: relative;
    background-color: #f3f6fb!important;
    color: #000!important;
    padding-top: 140px;
  }
  
  .newFooter a {
    color: #000!important;
  }

  .newFooter .partners h4 {
    color: #000!important;
}

.newFooter .container p, .newFooter li a, .newFooter li a.active {
  color:  #000!important;
}
.nav-item .mb-2{ color: #000!important;}