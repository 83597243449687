@import url('https://fonts.googleapis.com/css2?family=Anton&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{   font-family: "Poppins", serif; line-height: 30px; background: #f2f2f2;
}

.newNav{background-color: #fff!important; border-bottom: 1px solid #f1f1f1;}
.nav-item.dropdown .nav-link:focus, .nav-item.dropdown .nav-link:hover, .navbar-nav .show>.nav-link {
    color: rgb(255 127 70);
}
.newNav img { max-width: 80% !important;}
a#collasible-nav-dropdown {
    font-size: 16px;
    margin-right: 10px;
}
.newNav .navbar-nav a{font-size: 16px;
    margin-right: 10px;}
.newNav .navbar-nav a {
    display: block;
    color: #1a1a1a;
    font-weight: 500;
    padding: 10px 13px;
    position: relative;
    letter-spacing: 1px;
    font-size: 16px;
    text-transform: capitalize;
}
.stickyLink {
    position: absolute;
    right: 0;
    width:90px;
    text-align: center;
    height: 20px;
    border-top-left-radius: 10px;
    line-height: 9px;
    border-bottom-left-radius: 10px;
     padding: 5px 10px;
    font-size: 13px;
}
.blue{background-color: #4681ff;  top: 10%;}
.manSection {
    background-color: #4681ff;
    min-height: 200px;
    
    margin-bottom: 20px;
    margin: 10px 15px -60px;
    border-radius: 10px;
    position: relative;
    z-index: 4;margin-bottom: -100px;
}
.manSection .col-sm-9{ display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;}

    .man-img {
        width: auto;
        z-index: 10;
        width: inherit;
        position: absolute;
        bottom: 0;
        left: 0;
        height: auto;
        max-width: 27%;
        max-height: auto !important;
        object-fit: contain;
    }
.manSection h2{ font-size:40px; color:#fff; }
.manSection .whiteBtn{ width: auto; padding: 10px 15px; background-color: #fff; color:#4681ff; border-radius: 20px; margin-top: 10px;}
.orange{background-color: #ff7f46;     top: 35%;}
.green{background-color: #38c0a9;  top: 60%;}
.BannerNew{ min-height: 100vh; background-size: cover; background-position: center;background-repeat: no-repeat; }
.bluePatch{ width: 40%; height: 100vh; background-color: rgb(86 142 255 / 85%); display: flex ; justify-content: center; align-items: center;  flex-direction: column;  padding: 0px 35px;    ;}
.bluePatch h1 {
    font-size: 43px;
    text-transform: uppercase;
    color: #fff;
    text-align: left;
    font-weight: 500;
    font-family: "Anton", sans-serif;
    letter-spacing: 2px;
}
.yellow{color: #f6ef00;}
.bluePatch p { color: #fff; font-size: 17px;    text-align: left;}
.uspBox {display: flex; }
.shapdeDiv{width: 100%; height: 145px; background-color: #f3f6fb; position: relative; font-size: 20px; font-weight:600; color:#000; font-weight: 700;
    color: #000;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
 }
 .aboutTheSchool ,.aboutTheSchool img {
    position: relative; /* To position the :after pseudo-element correctly */
  }
  @keyframes float_up_down {
    0% {
      transform: translateY(0px);
      transform: translateY(0px);
      opacity: 1;
    }
  
    50% {
      transform: translateY(30px);
      transform: translateY(30px);
      opacity: 1;
    }
  
    100% {
      transform: translateY(0px);
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .aboutTheSchool:after {
    content: '';
    display: block;
    position: absolute;
    top: 11%;
    right: 180px;
    width: 90px;
    height: 91px;
    background-image: url('../img/book_shapes.7e726b4b.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity:1; /* Optional for transparency */
  

      animation-name: float_up_down; 
    animation-duration: 3s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
    -webkit-animation-name: float_up_down; 
    -webkit-animation-duration: 3s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    
    -moz-animation-name: float_up_down; 
    -moz-animation-duration: 3s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    -ms-animation-name: float_up_down; 
    -ms-animation-duration: 3s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    -o-animation-name: float_up_down; 
    -o-animation-duration: 3s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;

   }

.manSection{ position: relative;}
   .aboutTheSchool:before   {
    content: '';
    display: block;
    position: absolute;
    top: 11%;
    left: 120px;
    width: 90px;
    height: 91px;
    background-image: url('../img/arrow_shapes.9ee0a6f2.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity:1; /* Optional for transparency */
  

      animation-name: float_up_down; 
    animation-duration: 3s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
    -webkit-animation-name: float_up_down; 
    -webkit-animation-duration: 3s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    
    -moz-animation-name: float_up_down; 
    -moz-animation-duration: 3s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    -ms-animation-name: float_up_down; 
    -ms-animation-duration: 3s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    -o-animation-name: float_up_down; 
    -o-animation-duration: 3s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;

   }
     .manSection:before {
    content: '';
    display: block;
    position: absolute;
    
    top: -55%;
    left: 199px;
    width: 90px;
    height: 91px;
    background-image: url('../img/arrow_shapes.9ee0a6f2.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity:1; /* Optional for transparency */
  

      animation-name: float_up_down; 
    animation-duration: 3s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
    -webkit-animation-name: float_up_down; 
    -webkit-animation-duration: 3s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    
    -moz-animation-name: float_up_down; 
    -moz-animation-duration: 3s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    -ms-animation-name: float_up_down; 
    -ms-animation-duration: 3s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    -o-animation-name: float_up_down; 
    -o-animation-duration: 3s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;

   }
   .aboutTheSchool img:before {
    content: '';
    display: block;
    position: absolute;
    top: 13%;
    left: 180px;
    width: 90px;
    height: 91px;
    background-image: url('../img/about_shape_2.bc0556bb.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity:1; /* Optional for transparency */
  

      animation-name: float_up_down; 
    animation-duration: 3s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
    -webkit-animation-name: float_up_down; 
    -webkit-animation-duration: 3s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    
    -moz-animation-name: float_up_down; 
    -moz-animation-duration: 3s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    -ms-animation-name: float_up_down; 
    -ms-animation-duration: 3s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    -o-animation-name: float_up_down; 
    -o-animation-duration: 3s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;

   }
.shapdeDiv img {
    position: absolute;
    left: -29px;
    top: 27%;
    max-height: 46%;
}
.shapdeDiv::after{  width: 0; height: 0;  	border-top: 0 solid transparent;
	border-left: 30px solid #f3f6fb;
	border-bottom: 145px solid #fff; position: absolute;content:""; right: 0;}
    .shapdeDiv::before {
        content: "";
        width: 0px;
        height: 0;
        border-top: 145px solid #fff;
        border-right: 30px solid #f3f6fb;
        border-bottom: 0px solid #af111100;
        top: 0;
        position: absolute;    left: 0;

    }


    .borderLeft {
        border-right: 2px solid ;
        position: absolute;
        width: 2px;
        left: -15px;
        rotate: 12deg;
        content: "";
        width: 30px;
        height: 145px;
        top: -3px;
    }
    
    .blueBorder{border-color: #4681ff;     }
    .orangeBorder{border-color: #ff7f46;     }
.greenBorder{border-color: #38c0a9;  }
h2.mainFont { color:#4681ff; font-size: 30px; text-transform: capitalize; font-weight: 600;}
.aboutTheSchool{ min-height: 100vh; background-size: cover; background-position: top;background-repeat: no-repeat;  padding:15% 0 5%; }
 .aboutTheSchool img{ max-width: 85%;}
 .accordion-item {
    margin-bottom: 15px;
}
button.accordion-button.collapsed {
    font-size: 17px;
    font-weight: 500;
}
.accordion-button:not(.collapsed) {
    color: #ffff;
    background-color: #ff7f46;
    box-shadow: inset 0 calc(var(--bs-accordion-border-width)* -1) 0 var(--bs-accordion-border-color);
    font-weight: 600;
}

.imageDiv2 {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    /* top: -100px; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 6px solid #fff;
    /* position: absolute; */
    /* top: -29px; */
    margin-bottom: -38px;
    z-index: 23333;
    position: relative;
    margin: 0px auto -49px;
}
.captionDiv2 {
    padding: 50px 0px;
    color: #fff;
}
 .accordion-header button:before {
    position: absolute;
    content: "\f00c";
    color:#fff;
    background: #ff7f46;
    font-family: "Font Awesome 5 Free"; /* Use "Font Awesome 5 Free" if using FA5 */
    font-weight: 600;
    top: 14px;
    left: 23px;
    font-size: 12px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.accordion-header button {
    font-size: 17px;
    font-weight: 500;
    color: var(--paraColor);
    font-family: var(--paraFont);
    background: var(--colorWhite);
    padding: 15px 55px 15px 70px;
    border-radius: 5px !important;
    box-shadow: none;
    transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -ms-transition: all .3s linear;
    -o-transition: all .3s linear;
}
.accordion-item.orangeB button:before , .orangeBB {
    background: #ff7f46;
}
.accordion-item.greenB button:before,.greenBB {
    background: #1bb69c;
}
.accordion-item.blueB button:before ,.blueBB{
    background: #4682ff;
}


  .accordion-header button:not(.collapsed):before {
    background: #fff;
    color: #ff7f46;
}


.productDiv2 { min-height: 200px; border-radius: 12px; padding: 20px;}

 
.tf__work_single.red a {
    color: #ff5b5c;
}
.captionDiv2 a {
    position: relative;
    z-index: 1;
    padding: 10px;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    bottom: -25px;
    height: 40px;
    width: 40px;
    display: inline-block;
    line-height: 21px;
}

.USP { background-color: #fff;}
 